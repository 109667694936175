import {useRouter} from 'next/router';
import {useEffect, useRef} from 'react';
import {sendHitForGoogleAnalyticsEvents, viewCartEvent} from '@components/datalayer/google';
import {DeterminePageType} from '@components/datalayer/helpers/determine-page-type';
import {usePreviousRoute} from '@helpers/hooks/usePreviousRoute';
import {useSession} from 'next-auth/react';
import {cleanAndReformatToE164WIthPlusCharacterAdded} from '@helpers/common/format/format-phone.mjs';

const SendPageViewHitToAnalyticsAPI = (hitEvent) => {
  return fetch('/api/analytics/events/page-view', {
    method: 'POST',
    body: JSON.stringify(hitEvent),
    headers: {'Content-Type': 'application/json'}
  });
};

export const TriggerTrackerPageViews = () => {
  const {data: session} = useSession();
  const router = useRouter();

  const hasInitialLoadBeenHandled = useRef(false);
  const prevRoute = usePreviousRoute();
  const currentPath = useRef(undefined);

  const processHitEvent = (path) => {
    // console.log('[TriggerTrackerPageViews][processHitEvent]: ', path);
    if (document.readyState !== 'complete') {
      return;
    }

    const excludedPaths = ['cart', 'checkout', 'order'];

    const pageType = DeterminePageType() === 'Unknown Page Type' ? null : DeterminePageType();
    const hitEvent = {
      path: path,
      pageType: pageType ?? null,
      page: {
        page_title: document.title,
        page_referrer: prevRoute ?? null,
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_hostname: window.location.hostname,
        page_search: window.location.search,
        page_hash: window.location.hash
      }
    };
    // console.log('[sendHitEvent][hitEvent]: ', hitEvent);
    // Use event_id for Facebook Pixel event
    if (excludedPaths.some((exclude) => path.includes(exclude)) === false) {
      if (window?.fbq) {
        const eventId = `event.PageView.${generateRandomEventId(14)}`;
        window.fbq('track', 'PageView', {event_id: eventId});
        // console.log('FB Pixel PageView tracked:', eventId);
        hitEvent.event_id = eventId;
      }
      // console.log({hitEvent})
      sendHitForGoogleAnalyticsEvents(hitEvent, session);
      // SendPageViewHitToAnalyticsAPI(hitEvent).then().catch(console.error); // @TODO - RE_ENABLE ONCE MYSQL SERVER 2 IS BACK ONLINE
      // sendHitForSkarAudioAnalytics(hitEvent, session);
    }
  };

  useEffect(() => {
    const handleRouteChange = (path) => {
      // Process only if it's the first load or the path has changed
      if (!hasInitialLoadBeenHandled.current || currentPath.current !== path) {
        currentPath.current = path; // Update the current path

        if (!hasInitialLoadBeenHandled.current) {
          hasInitialLoadBeenHandled.current = true;
        }
        processHitEvent(path);
      }
    };

    if (!hasInitialLoadBeenHandled.current) {
      currentPath.current = window.location.pathname;
      handleRouteChange(window.location.pathname);
    }

    if (document.readyState === 'complete') {
      router.events.on('routeChangeComplete', handleRouteChange);
      router.events.on('hashChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
        router.events.off('hashChangeComplete', handleRouteChange);
      };
    }
  }, []);

  return null;
};

export const setUserDataWithUserSessionForGTagEvent = (session) => {
  if (!session) return;
  const user = session?.user;
  const email = user?.email;

  const phone = user?.primary_phone ?? null;
  const preparedPhoneNumber = cleanAndReformatToE164WIthPlusCharacterAdded(phone);

  // const hashedEmail = email ? prepareAndHashEmail(email) : null;
  // const hashedPhone = phone ? prepareAndHashPhoneNumberE164(user?.primary_phone) : null;

  const address = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    street: user?.address?.address_line_1,
    city: user?.address?.city,
    region: user?.address?.state_code,
    postal_code: user?.address?.zip_code,
    country: user?.address?.country_code
  };

  let adwordsUser = {
    email: email,
    phone: preparedPhoneNumber,
    // sha256_email_address: hashedEmail,
    // sha256_phone_number: hashedPhone,
    address
  };

  Object.keys(adwordsUser).forEach((key) => {
    if (adwordsUser[key] == null) {
      delete adwordsUser[key];
    }
  });
  Object.keys(adwordsUser.address).forEach((key) => {
    if (adwordsUser?.address[key] == null) {
      delete adwordsUser.address[key];
    }
  });

  if (Object.keys(adwordsUser?.address)?.length === 0) {
    delete adwordsUser.address;
  }
  // console.log({adwordsUser})
  return adwordsUser;
};

// When the cart page is loaded
export function ViewCartTagOnLoad({data, lineItems}) {
  useEffect(() => viewCartEvent(data, lineItems), []);
  return null;
}

export const GlobalExecute = ({execute}) => {
  const router = useRouter();
  useEffect(() => {
    execute();
  }, [router.asPath]);
  return null;
};

export function GetGoogleCookie() {
  let cookies = window.document.cookie;
  cookies = cookies
    .split(';')
    .map((cookie) => cookie.split('='))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value)
      }),
      {}
    );
  return cookies._ga;
}

const generateRandomEventId = (length = 14) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = chars.charAt(Math.floor(Math.random() * 52));
  for (let i = 1; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars?.length));
  }
  return result;
};

function logIfRouteChanges(firstRouteChangeFired, sessionIsLoading, account) {
  if (firstRouteChangeFired) {
    console.log('[TriggerTrackerPageViews][sessionIsLoading]: ', {
      sessionIsLoading: sessionIsLoading,
      isCustomer: account?.isMember ? true : false
    });
  }
}
