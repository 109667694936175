import cn from 'clsx';
import {
  priceHelper,
  returnHighestAndLowestPriceValues,
  siteSettingsV2
} from '../../../helpers/config-master.mjs';
import {niceMoney} from '@helpers/common/number.mjs';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';

export const TwoPrices = ({item, isDealer, className = 'text-sm'}) => {
  // console.log({twoPrices_item: item})
  const isMobile = useMobileOrTablet();

  const msrpPrice = Number(item?.price_msrp);
  const mapPrice = Number(item?.price_map);
  const dealerPrice = Number(item?.price_dealer);

  const productTypeIsBundle = item?.product_type === 'bundle';

  const isSaleEnabled = item ? priceHelper.saleEnabledFor(item) : false;
  // console.log({high_and_low_prices: {isSaleEnabled, msrpPrice, mapPrice}})

  const isInStock = item?.in_stock === true;
  const isNotInStock = item?.in_stock === false;
  const isPreorderEligible =
    !isInStock &&
    (item?.pre_order_info?.is_pre_order_eligible === 1 || item?.variant?.is_pre_order_eligible);
  const isPreOrderEligibleAndActive = isPreorderEligible;

  const isDealerAndProductIsNotInStock = isDealer && isNotInStock;
  // console.log({high_and_low_prices: {isInStock, msrpPrice, mapPrice}})

  const [high, low] = item
    ? returnHighestAndLowestPriceValues(item, {isDealer})
    : [msrpPrice, mapPrice];
  // console.log({twoPrices_high_and_low_prices: {high, low}})
  if (isNaN(high) || isNaN(low)) {
    return;
  }

  const isMobileView = isMobile === true;
  const isDesktopView = isMobile === false;

  const showDealerMobilePricing = isMobileView && isDealer === true;
  const showDealerDesktopPricing = isDesktopView && isDealer === true;
  // console.log({isMobileView, showDealerMobilePricing, isDesktopView, showDealerDesktopPricing})

  if (productTypeIsBundle) {
    if (isMobile) {
      return <IsBundlePricingDivMobile high={high} low={low}/>;
    } else {
      return <IsBundlePricingDivDesktop high={high} low={low}/>;
    }
  }

  if (showDealerMobilePricing) {
    return <IsDealerDivMobile high={high} low={low}/>;
  }

  if (showDealerDesktopPricing) {
    return <IsDealerDivDesktop high={high} low={low} className={className}/>;
  }

  return (
    <div>
      {isPreOrderEligibleAndActive
        ? isPreorder({
          className,
          low
        })
        : isSaleEnabled
          ? isSaleEnabledDiv({className, high, low})
          : generalPriceDiv({
            className,
            high,
            low
          })}
    </div>
  );
};

function isPreorder({className, price}) {
  return (
    <>
      {/*<p className={cn(className, 'font-light')}>*/}
      {/*	<span>Normal Price: </span>*/}
      {/*	<span className="line-through">{niceMoney(high)}</span>*/}
      {/*</p>*/}
      <div className='flex-1 flex text-center align-center justify-start'>
        <div
          className='border rounded rounded-2 text-xs pl-2 pr-2 py-2 text-blue border-gray-800'
          style={{
            backgroundColor: 'rgb(255,251,235)'
          }}>
          <p className={cn(className)}>
            <span
              style={{
                fontSize: '18px'
              }}>
              Preorder Price:{' '}
            </span>
            <strong>{niceMoney(price)}</strong>
          </p>
          Pre-Order Item
        </div>
      </div>
    </>
  );
}

const generalPriceDiv = ({className, high, low}) => {
  return (
    <>
      <p className={cn(className)}>
        <span className='font-light text-sm'>MSRP: </span>
        <span className='font-light line-through text-sm'>{niceMoney(high)}</span>
      </p>
      <p className={cn(className)}>
        <span className='font-light text-md'>Price: </span>
        <span className='fw-bold text-md'>{niceMoney(low)}</span>
      </p>
    </>
  );
};

const IsDealerDivMobile = ({high, low}) => {
  return (
    <div className='pt-2'>
      <p className={'font-light'}>
        <span>MAP: </span>
        <span>{niceMoney(high)}</span>
      </p>

      <p className=''>
        <strong>Dealer: </strong>
        <strong>{niceMoney(low)}</strong>
      </p>
    </div>
  );
};

const IsDealerDivDesktop = ({high, low, className}) => {
  // console.log({low, high, className})
  return (
    <div className='pt-2'>
      <p className={cn(className, 'font-light')}>
        <span>MAP: </span>
        <span>{niceMoney(high)}</span>
      </p>

      <p className={cn(className)}>
        <strong>Dealer: </strong>
        <strong>{niceMoney(low)}</strong>
      </p>
    </div>
  );
};

const isSaleEnabledDiv = ({className, high, low}) => {
  return (
    <>
      <p className={cn(className, 'font-light')}>
        <span>Normal Price: </span>
        <span className='line-through'>{niceMoney(high)}</span>
      </p>

      <p className={cn(className)}>
        {/*<span style={{fontWeight: 'bolder'}}>SALE PRICE: </span>*/}
        <span style={{fontWeight: '500'}}>Tax Season Sale: </span>
        <strong className='font-bold text-l' style={{color: 'rgba(4,43,138, 1)'}}>
          {niceMoney(low)}
        </strong>
      </p>
    </>
  );
};

const IsBundlePricingDivMobile = ({low, high}) => {
  const saleAttributes = siteSettingsV2.getSaleSettings();
  const specialPriceText = saleAttributes?.layoutSettings?.sideCartSpecialPriceText ?? siteSettingsV2?.layoutSettings?.sideCartSpecialPriceText;

  return (
    <div className='pt-1.5 ml-2'>
      <p style={{fontSize: '14px', fontWeight: '300', marginTop: '-3px'}}>
        <span>Normal: </span>
        <span style={{textDecoration: 'line-through'}}>${high}</span>
      </p>

      <p style={{fontSize: '15px', fontWeight: '500', marginTop: '-2px'}}>
        <span>{specialPriceText}</span>
        <span style={{color: 'rgb(196,20,43)'}}>${low}</span>
      </p>
    </div>
  );
};

const IsBundlePricingDivDesktop = ({low, high}) => {
  const saleAttributes = siteSettingsV2.getSaleSettings();
  const sideCartSpecialPriceText = saleAttributes?.layoutSettings?.sideCartSpecialPriceText ?? siteSettingsV2?.layoutSettings?.sideCartSpecialPriceText;

  return (
    <div className='pt-1.5 ml-2'>
      <p style={{fontSize: '14px', fontWeight: '300', marginTop: '-3px'}}>
        <span>Normal: </span>
        <span style={{textDecoration: 'line-through'}}>${high}</span>
      </p>

      <p style={{fontSize: '15px', fontWeight: '500', marginTop: '-2px'}}>
        <span>{sideCartSpecialPriceText}</span>
        <span style={{color: 'rgb(196,20,43)'}}>${low}</span>
      </p>
    </div>
  );
};

// console.log({
// 	variant: {
// 		price: item?.variant?.price,
// 		price_map: item?.variant?.price_map,
// 		price_msrp: item?.variant?.price_msrp,
// 	},
// 	displayPrices: {
//     strikeoutPrice,
// 		// price,
// 		salePrice,
// 	},
// })

// if (product.sku === 'VS-2X10GM-1999-2006-EXTCAB') {
//   console.dir({
//     logging_from: 'returnHighestAndLowestPriceValues',
//     sku: product.sku,
//     product: product,
//     props: props
//   }, {depth: null})
// }
